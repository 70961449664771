import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV, PUBLIC_SENTRY_DSN } from '$env/static/public';

const isProduction = PUBLIC_ENV === 'production';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: PUBLIC_SENTRY_DSN, // ローカルでの開発中などは、空文字列にしておけば Sentry は動作しない
	environment: isProduction ? 'production' : 'development',
	tracesSampleRate: isProduction ? 0.05 : 0.2,
	replaysSessionSampleRate: isProduction ? 0.1 : 0,
	replaysOnErrorSampleRate: isProduction ? 0.1 : 0,
	integrations: [Sentry.replayIntegration()]
});

export const handleError = Sentry.handleErrorWithSentry();
